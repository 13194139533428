import request from '@/utils/request'

// 查询运营商通用套餐
export function findCarrierProducts(params) {
  return request({
    url: `/carrier_products`,
    method: 'get',
    params: params
  })
}

// 创建运营商通用套餐
export function importCarrierProducts(data) {
  return request({
    url: `/carrier_products/import`,
    method: 'post',
    data
  })
}

// 查询单个运营商通用套餐表单值
export function findCarrierProductForm(id) {
  return request({
    url: `/carrier_products/${id}/form`,
    method: 'get'
  })
}

// 更新运营商通用套餐
export function updateCarrierProduct(id, data) {
  return request({
    url: `/carrier_products/${id}`,
    method: 'put',
    data
  })
}

// 批量更新运营商通用套餐有效性
export function batchUpdateCarrierProductsEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/carrier_products/effective/batch`,
    method: 'patch',
    data
  })
}

// 运营商账户的套餐选项数据
export function findCarrierAccountProductOptions(carrierAccountId) {
  return request({
    url: `/carrier_products/options`,
    method: 'get',
    params: { carrier_account_id: carrierAccountId }
  })
}

// 查询运营商通用套餐
export function exportCarrierProducts(params) {
  return request({
    url: `/carrier_products/export`,
    method: 'post',
    params
  })
}
